import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import '../utils/css/views/challenges.css';
import BoxChallenge from '../components/challenges/BoxChallenge';
import ChalStats from '../components/challenges/chalStats';
import IconHistory from '../assets/icon/update.png';
// import BarChart from '../components/challenges/BarChartStats';
import Loader from '../components/general/LoaderGIF';
import { useGlobalState } from '../views/GlobalStateContext';

function Challenges() {
    let navigate = useNavigate();

    const [gain, setGain] = useState(0);
    const [uvc, setUvc] = useState(0);
    const [ca_trim, setCa_trim] = useState(0);
    const [dataStats, setDataStats] = useState();
    const [dataCurrent, setDataCurrent] = useState({
        current_challenge_trimestriel: [],
        current_challenge_exceptionnel: [],
    });
    const [challengesForUser, setChallengesForUser] = useState([]); // Nouvel état pour les challenges spécifiques à l'utilisateur
    const [isLoading, setIsLoading] = useState(true);

    const userToken = localStorage.getItem('userToken');
    const { urlAPI } = useGlobalState();

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
        }],
    });

    useEffect(() => {
        if (dataStats) {
            let newLabels = [];
            let newData = [];
            let newGain = 0;
            let newUvc = 0;
            let newCa_trim = 0;
            const temp_chart = {};

            Object.keys(dataStats).forEach(key => {
                const totalGain = dataStats[key].totalGain;
                const sales = dataStats[key].sales;

                sales.forEach(item => {
                    if (!temp_chart[item.challenge_name]) {
                        temp_chart[item.challenge_name] = item.gain;
                    } else {
                        temp_chart[item.challenge_name] += item.gain;
                    }
                });

                newCa_trim = parseFloat((newCa_trim + dataStats[key].totalCa).toFixed(2));
                newGain += totalGain;
                newUvc += dataStats[key].totalQtt;
            });

            newLabels = Object.keys(temp_chart);
            newData = Object.values(temp_chart);

            setChartData({
                labels: newLabels,
                datasets: [{
                    label: "Gains",
                    data: newData,
                }],
            });

            setCa_trim(newCa_trim);
            setGain(newGain);
            setUvc(newUvc);
        }
    }, [dataStats]);

    useEffect(() => {
        fetchDetailsChall();
        fetchStasts();
        fetchIfOpenForSpecificUser();
    }, []);

    const handleClickHistory = () => {
        navigate("/challenges/statistiques", {
            state: {
                chartData: chartData,
                gain: gain,
                uvc: uvc,
                ca_trim: ca_trim,
                dataCurrent: dataCurrent
            }
        });
    };

    const fetchDetailsChall = async () => {
        const url = `${urlAPI}/challenge/getAllSummaryChallenge`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données challenges');
            }
            let data = await response.json();

            const totalGain = data.reduce((acc, challenge) => acc + parseFloat(challenge.gain), 0);
            setGain(totalGain);

            const totalUVC = data.reduce((acc, challenge) => acc + parseInt(challenge.qtt), 0);
            setUvc(totalUVC);

            const challengesTrimestriels = data.filter(challenge => challenge.type === "0");
            const challengesExceptionnels = data.filter(challenge => challenge.type === "1" || challenge.type === "2");

            setDataCurrent({
                current_challenge_trimestriel: challengesTrimestriels,
                current_challenge_exceptionnel: challengesExceptionnels,
            });

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchStasts = async () => {
        const url = `${urlAPI}/sell/sellStats`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des stats');
            }
            let data = await response.json();
            setDataStats(data)
        } catch (error) {
            console.error(error);
        }
    }

    const fetchIfOpenForSpecificUser = async () => {
        const url = `${urlAPI}/challenge/getTemporarilyOpen`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des challenges');
            }
            let data = await response.json();
            // Mise à jour de l'état avec les challenges spécifiques à l'utilisateur
            setChallengesForUser(data.challenges || []); // Assure que 'challenges' est extrait correctement
        } catch (error) {
            console.error(error);
        }
    }

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className='container-challenges'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data-challenges'>
                <Header title={"Mes challenges"} bold={"challenges"} />
                <div className='container-without-header'>
                    <div className='container-bloc-contenue'>
                        <h2 className='subtitle-challenges'>Mes statistiques</h2>
                        <div className='container-bloc-stats'>
                            <ChalStats chartData={chartData} gain={gain} uvc={uvc} ca_trim={ca_trim} />
                            <div className='container-history' onClick={handleClickHistory}>
                                <img className='icon-history pointer' src={IconHistory} alt="Voir l'historique" />
                                <label className='text-history pointer'>Voir l'historique</label>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className='bloc-contenue'>
                            {dataCurrent.current_challenge_trimestriel.length > 0 && dataCurrent.current_challenge_trimestriel.map((challenge, index) => (
                                <BoxChallenge
                                    key={index}
                                    title={"Le challenge trimestriel"}
                                    name={challenge.name}
                                    dateStart={challenge.start_date}
                                    dateEnd={challenge.end_date}
                                    dateLimit={challenge.limit_date}
                                    gain={`${challenge.gain}`}
                                    status={
                                        challenge.isValidated ? "Validé" :
                                            (challenge.document === true && challenge.gain > 0 && today > challenge.start_date && today < challenge.end_date) ? "Complet" :
                                                (challenge.document === true && challenge.gain > 0 && today > challenge.end_date && today < challenge.limit_date) ? "En cours de validation" :
                                                    (challenge.document === false && today > challenge.end_date && today < challenge.limit_date && challenge.gain == 0) ? "Validation - incomplet" :
                                                        "A compléter"
                                    }
                                    document={challenge.document}
                                    type={challenge.type}
                                    id={challenge.id}
                                    urlSellRedirect={'/challenges/challenge_trimestriel_details/ventes/'}
                                    urlMoreRedirect={'/challenges/challenge_trimestriel_details/'}
                                />
                            ))}
                            {dataCurrent.current_challenge_exceptionnel.length > 0 && dataCurrent.current_challenge_exceptionnel.map((challenge, index) => (
                                <BoxChallenge
                                    key={index}
                                    title={"Le challenge du moment"}
                                    name={challenge.name}
                                    dateStart={challenge.start_date}
                                    dateEnd={challenge.end_date}
                                    dateLimit={challenge.limit_date}
                                    gain={`${challenge.gain}`}
                                    status={
                                        challenge.isValidated ? "Validé" :
                                            (challenge.document === true && challenge.gain > 0 && today > challenge.start_date && today < challenge.end_date) ? "Complet" :
                                                (challenge.document === true && challenge.gain > 0 && today > challenge.end_date && today < challenge.limit_date) ? "En cours de validation" :
                                                    (challenge.document === false && today > challenge.end_date && today < challenge.limit_date && challenge.gain == 0) ? "Validation - incomplet" :
                                                        "A compléter"
                                    }
                                    document={challenge.document}
                                    type={challenge.type}
                                    id={challenge.id}
                                    urlSellRedirect={'/challenges/challenge_exceptionnel_details/ventes/'}
                                    urlMoreRedirect={'/challenges/challenge_exceptionnel_details/'}
                                />
                            ))}
                            {/* Afficher les challenges spécifiquement ouverts pour l'utilisateur */}
                            {challengesForUser.length > 0 && challengesForUser.map((challenge, index) => (
                                <BoxChallenge
                                    key={`specific-${index}`}
                                    title={"Challenge ouvert spécifiquement"}
                                    name={challenge.name}
                                    dateStart={challenge.start_date}
                                    dateEnd={challenge.end_date}
                                    dateLimit={challenge.limit_date}
                                    gain={challenge.gain}
                                    status={"Temporaire"}
                                    document={false}
                                    type={challenge.type_challenge}
                                    id={challenge.id}
                                    urlSellRedirect={`/challenges/challenge_${Number(challenge.type_challenge) === 0 ? 'trimestriel' : 'exceptionnel'}_details/ventes/`}
                                    urlMoreRedirect={`/challenges/challenge_${Number(challenge.type_challenge) === 0 ? 'trimestriel' : 'exceptionnel'}_details/`}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Challenges;
