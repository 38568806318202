import { useEffect, useState } from 'react';
import Homepage from './views/HomePage';
import Connexion from './views/Connexion';
import Inscription from './views/Inscription';
import Challenges from './views/Challenges';
import Beneficiaires from './views/Beneficiaires';
import Documents from './views/Documents';
import ChallengeStatistiques from './views/ChallengeStatistiques';
import DetailsChallengeTrimestriel from './views/DetailsChallengeTrimestriel';
import DetailsChallengeExceptionnel from './views/DetailsChallengeExceptionnel';
import Profil from './views/Profil';
import ProfilEmail from './views/ProfilEmail';
import ProfilPassword from './views/ProfilPassword';
import PageInconnu from './views/PageInconnu';
import ForgetPassword from './views/ForgetPassword.js';
import ErrorPage from './views/ErrorPage';
import Formation from './views/Formation.js';
import ResizePage from './views/ResizePage.js';
import InvitationBeneficiaire from './views/InvitationBeneficiaire.js';
import Ventes from './views/Ventes'
import InscriptionBeneficiaire from './views/InscriptionBeneficiaire.js';
import ResizeRedirecte from './components/general/ResizeRedirect.js'
import Faq from './views/Faq.js';
import { useGlobalState } from './views/GlobalStateContext';
import Maintenance from './views/Maintenance.js';

import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import UpdateVentes from './views/UpdateVentes';


function App() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const userToken = localStorage.getItem('userToken');
  const { urlAPI } = useGlobalState();

  function routeProtected() {
    if (!location.pathname.includes('/inscription') && !location.pathname.includes('/mot_de_passe') && !location.pathname.includes('/beneficiaire/invitation') && !location.pathname.includes('/resize_page')){
      navigate('/');
    }
  }

  useEffect(() => {

    const getIsConnected = async () => {
      const url = `${urlAPI}/auth/isConnected`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userToken,
          },
        });
        
        if (response.status === 401) {
          routeProtected(); // Renvoie false si l'utilisateur n'est pas connecté
        }
    
        if (!response.ok) {
          routeProtected(); // Renvoie false si l'utilisateur n'est pas connecté
        }
    
      } catch (error) {
        console.error('Erreur lors de la requête :', error);
        routeProtected(); // Renvoie false en cas d'erreur
      }
    };

    getIsConnected()
  }, [location.pathname, navigate]);

  return (
    <div>
       <ResizeRedirecte />
      <Routes>
        {/* <Route path="*" element={<Maintenance pageName="maintenance" />} /> */}
        <Route path="/" element={<Connexion pageName="connexion" />} />
        <Route path="/page_inconnu" element={<PageInconnu pageName="redirection" />} />
        <Route path="*" element={<Navigate to="/page_inconnu" />} />
        <Route path="/error" element={<ErrorPage pageName="erreur" />} />
        <Route path="/inscription" element={<Inscription pageName="inscription" />} />
        <Route path="/inscription_beneficiaire" element={<InscriptionBeneficiaire pageName="InscriptionBeneficiaire" />} />
        <Route path="/beneficiaire/invitation" element={<InvitationBeneficiaire pageName="Invitation_beneficiaire" />} />
        <Route path="/mot_de_passe" element={<ForgetPassword pageName="mot_de_passe_oublie" />} />
        <Route path="/tableau_de_bord" element={<Homepage pageName="dashboard" />} />
        <Route path="/challenges" element={<Challenges pageName="challenges" />} />
        {/* <Route path="/challenges/challenges_trimestriel" element={<Main_challenge pageName="challenge_trimestriel" />} /> */}
        <Route path="/challenges/statistiques" element={<ChallengeStatistiques pageName="statistiques" />} />
        <Route path='/challenges/challenge_trimestriel_details/:id' element={<DetailsChallengeTrimestriel pageName="challenge_trimestriel_details" />} />
        <Route path='/challenges/challenge_exceptionnel_details/:id' element={<DetailsChallengeExceptionnel pageName="challenge_exceptionnel_details" />} />
        <Route path='/challenges/challenge_trimestriel_details/ventes/:id' element={<Ventes pageName="ventes" />} />
        <Route path='/challenges/challenge_exceptionnel_details/ventes/:id' element={<Ventes pageName="ventes" />} />
        <Route path='/challenges/challenge_trimestriel_details/update_vente/:id' element={<UpdateVentes pageName="update_vente" />} />
        <Route path='/challenges/challenge_exceptionnel_details/update_vente/:id' element={<UpdateVentes pageName="update_vente" />} />
        <Route path="/beneficiaires" element={<Beneficiaires pageName="beneficiaires" />} />
        <Route path="/formations" element={<Formation pageName="formations" />} />
        <Route path="/documents" element={<Documents pageName="documents" />} />
        <Route path="/profil" element={<Profil pageName="profil" />} />
        <Route path="/profil/email" element={<ProfilEmail pageName="profil_email" />} />
        <Route path="/profil/password" element={<ProfilPassword pageName="profil_password" />} />
        <Route path="/resize_page" element={<ResizePage pageName="resize_page" />} />
        <Route path="/FAQ" element={<Faq pageName="FAQ" />} /> 
        {/* Ajoutez d'autres routes ici si nécessaire */}
      </Routes>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
