import React from 'react';
import '../../utils/css/components/challenges/addSell.css';
import visibilityImg from '../../assets/icon/moreInfo.png';
import { useNavigate } from 'react-router-dom';
import imgAddSell from '../../assets/icon/add_circle.png'

const AddSell = (props) => {
    let navigate = useNavigate();

    // console.log(`${props.pathMore}${props.id}`)

    let modificationOn

        if (props.status === "Validé"){
            modificationOn = false
        }else {
            modificationOn = true
        }

        let addSellOn 
        
        if((props.status === "A compléter" && props.gain != 0) || props.status === "Complet" || (props.status === "Validation - incomplet" && props.gain != 0) || props.status === "En cours de validation" || props.status === "Validé"){
            addSellOn = false;
        }else {
            addSellOn = true;
        }

    const handleClickMore = () => {

        navigate(`${props.pathMore}${props.id}`, {
            state: {
                name: props.name,
                limitDate: props.limit_date,
                type: props.type,
                id: props.id,
                dateEnd: props.end_date,
                modificationOn: modificationOn,
                addSellOn: addSellOn
            }
        });
    };

    const handleClickSell = () => {
        navigate(`${props.pathSell}${props.id}`, {
            state: {
                name: props.name,
                limitDate: props.limit_date,
                type: props.type,
                id: props.id,
                dateEnd: props.end_date
            }
        });
    };

    return (
        <div className='container-all-addSell'>
            <div className="container-moreInfo-sell" onClick={handleClickMore}>
                <img className='icon-moreInfo-sell' src={visibilityImg} alt="Plus d'informations" />
                <p className='text-moreInfo-sell'>Voir le détail</p>
            </div>
            {
                addSellOn && (
                    <div className="button-addSell" onClick={handleClickSell}>
                        <img className="icon-addSell" src={imgAddSell} alt="Ajouter des ventes" />
                        <label className="text-addSell">J'ajoute mes ventes</label>
                    </div>
                )
            }

        </div>
    );
};

export default AddSell;
